//
//

$(document).foundation();

const $body = $('body');

const timeMedium = 400;

const $searchTrigger = $('#trigger-site-search');
const $searchForm = $('#form-site-search')
const $searchInput = $('#terms-site-search');

const $navManualHamburger = $('#manual-hamburger');
const $navManuals = $('#manual-navigation');
const $namManualTrigger = $navManuals.find('.manual-title > a');

const $currentNav = $('#current-manual-nav');
const $currentNavTrigger = $('#current-manual-nav > h2');
// const $currentNavChannels = $('#current-manual-channels'); // this is the ul

const $scrollToTop = $('#wrap-top-of-page');
//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -

$(function(){

  //
  // = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
  $searchInput.attr('tabindex',-1);

  $searchTrigger.attr({
    'role': 'button',
    'aria-controls': 'form-site-search',
    'aria-label': 'site search form',
    'aria-expanded': 'false'
  })
  .addClass('js-trigger')
  .on('click',function(e){
    const $trigger = $(this);
    const iconSearchOpen = $trigger.find('.fa-search');
    const iconSearchClose = $trigger.find('.fa-times');

    let formVisible = $searchForm.hasClass('js-show');

    if( formVisible ) {
      $searchForm.removeClass('js-show')
      $searchInput.attr('tabindex','-1');

      $trigger.attr('aria-expanded', 'false')

      iconSearchOpen.show();
      iconSearchClose.hide();

    } else {
      $searchForm.addClass('js-show');
      $searchInput.attr('tabindex',0);

      $trigger.attr('aria-expanded', 'true')

      iconSearchOpen.hide();
      iconSearchClose.show();

    };
  });

  //
  // = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
  let manualNavCount = 0;

  $navManuals.addClass('js-manual-nav');

  $namManualTrigger.each(function(){
    const $trigger = $(this);
    const $nav = $trigger.parent().next('ul');
    const navId = manualNavCount++;

    $nav
      .addClass('js-manual-subnav')
      .attr('id','manual-nav-'+navId);

    $trigger
      .attr({
        'role': 'button',
        'aria-controls': 'manual-nav-'+navId,
        'aria-label': 'Manual for '+ $trigger.text(),
        'aria-expanded': 'false'
      })
      .addClass('js-trigger')
      .on('click',function(e){
        e.preventDefault();
        let isOpen = $trigger.attr('aria-expanded') === 'true' ? true : false;
        if( isOpen ){

          if( $body.attr('class').match(/large/) ){
            $trigger.attr('aria-expanded','false');
            $nav.removeClass('open').slideUp('fast');
          } else {
            $trigger.attr('aria-expanded','false');
            $nav.removeClass('open');
          }

        } else {

          // if this is a desktop view, make sure only one menu is open at a time
          if( $body.attr('class').match(/large/) ){
            $namManualTrigger.attr('aria-expanded','false');
            $('.js-manual-subnav').removeClass('open').slideUp('fast');
            // then open THIS nav
            $trigger.attr('aria-expanded','true');
            $nav.addClass('open').slideDown('fast');
          } else {
            $trigger.attr('aria-expanded','true');
            $nav.addClass('open');
          }

        };
      });
  });

  $navManualHamburger.attr({
    'role': 'button',
    'aria-controls': 'manual-navigation',
    'aria-label': 'List of manuals',
    'aria-expanded': 'false'
  })
  .addClass('js-trigger')
  .on('click',function(e){
    e.preventDefault();

    const iconHamburgerOpen = $navManualHamburger.find('.fa-bars');
    const iconHamburgerClose = $navManualHamburger.find('.fa-times');

    let isOpen = $navManualHamburger.attr('aria-expanded') === 'true' ? true : false;
    if( isOpen ){
      $navManualHamburger.attr('aria-expanded','false');
      $navManuals.removeClass('open');

      iconHamburgerOpen.show();
      iconHamburgerClose.hide();

      // also close any open subnavs
      $namManualTrigger.attr('aria-expanded','false');
      $navManuals.find('ul').removeClass('open');
    } else {
      $navManualHamburger.attr('aria-expanded','true');
      $navManuals.addClass('open');

      iconHamburgerOpen.hide();
      iconHamburgerClose.show();
    };
  });

  //
  // = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -

  $currentNavTrigger
  .attr({
    'role': 'button',
    'aria-controls': 'current-manual-channels',
    'aria-label': 'Chapters in this manual',
    'aria-expanded': 'false'
  })
  .addClass('js-manual-channels-trigger')
  .on('click',function(e){
    e.preventDefault();

    let isOpen = $currentNavTrigger.attr('aria-expanded') === 'true' ? true : false;
    if( isOpen ){
      $currentNavTrigger.attr('aria-expanded','false');
      $currentNavChannels.removeClass('open');
    } else {
      $currentNavTrigger.attr('aria-expanded','true');
      $currentNavChannels.addClass('open');
    };
  });


  const $currentNavChannels = $currentNav.find('> ul');

  $currentNavChannels
  .attr('id', 'current-manual-nav-channels')
  .addClass('js-current-manual-nav-channels');

  let numberSubChannels = 1;

  const $currentNavSubChannels = $currentNavChannels.find('li > ul');
  // const $currentNavSubChannelTriggers;

  $currentNavSubChannels.each(function(){
    const $subNavUl = $(this);
    const $subNavTrigger = $subNavUl.prev('a');

    $subNavUl
      .attr('id','js-current-manual-nav-sub-'+ numberSubChannels++)
      .addClass('js-current-manual-nav-sub-channels')
      .find('a')
        .attr('tabindex','-1');

    $subNavTrigger
      .attr({
        'role': 'button',
        'aria-controls': 'js-current-manual-nav-sub-'+ numberSubChannels,
        'aria-label': 'Chapters in this section',
        'aria-expanded': 'false'
      })
      .addClass('js-current-manual-nav-sub-trigger')
      .on('click',function(e){
        e.preventDefault();

        let isOpen = $subNavTrigger.attr('aria-expanded') === 'true' ? true : false;
        if( isOpen ){
          $subNavTrigger.attr('aria-expanded','false');
          $subNavUl.removeClass('open')
          .find('a').attr('tabindex', '-1');
        } else {
          $subNavTrigger.attr('aria-expanded','true');
          $subNavUl.addClass('open')
            .find('a').removeAttr('tabindex');
        };
      });

  });


  $currentNavChannels
    .find('.current')
    .parentsUntil('.js-current-manual-nav-channels')
      .addClass('has-current-channel');

  $('.js-current-manual-nav-sub-channels.has-current-channel')
    .find('a')
      .removeAttr('tabindex');

  //
  // = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -

  $(document).scroll(function() {
    var docYPos = $(this).scrollTop();
    // console.log(docYPos);
  });

}); // end document.ready


const bpReset = () => {
  $body.removeClass('bp-small bp-medium bp-large bp-xlarge bp-xxlarge');

  //
  // reset the search trigger and form
  $searchTrigger.attr('aria-expanded','false');
  $searchForm.removeClass('js-show');
  $searchInput.attr('tabindex', '-1'); // mobile first


};

const bpSmall = () => {
  bpReset();
  $body.addClass('bp-small');
};

const bpMedium = () => {
  bpReset();
  $body.addClass('bp-medium');
};

const bpLarge = () => {
  bpReset();
  $body.addClass('bp-large');

  $searchInput.removeAttr('tabindex'); // allow the search form to be accessed

};

const bpXLarge = () => {
  bpReset();
  $body.addClass('bp-xlarge');

  $searchInput.removeAttr('tabindex'); // allow the search form to be accessed

};

const bpXXLarge = () => {
  bpReset();
  $body.addClass('bp-xxlarge');
};




// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
/* eslint-disable */
var bpListener = (function (parent, $) {
  // console.log('bpListener');
	var MediaQueryListener = function() {
		 this.afterElement = window.getComputedStyle ? window.getComputedStyle(document.body, ':after') : false;
		 this.currentBreakpoint = '';
		 this.lastBreakpoint = '';
		 this.init();
	};
	MediaQueryListener.prototype = {
	  init: function () {
		 var self = this;
		 if(!self.afterElement) {
			return;
		 }
		 self._resizeListener();
	  },
	  _resizeListener: function () {
		 var self = this;
		 $(window).on('resize orientationchange load', function() {
			// Regexp for removing quotes added by various browsers
			self.currentBreakpoint = self.afterElement.getPropertyValue('content').replace(/^["']|["']$/g, '');
			if (self.currentBreakpoint !== self.lastBreakpoint) {
			  $(window).trigger('breakpoint-change', self.currentBreakpoint);
			  self.lastBreakpoint = self.currentBreakpoint;
			}
		 });
	  }
	};
	parent.mediaqueryListener = parent.mediaqueryListener || new MediaQueryListener();
	return parent;
}(bpListener || {}, jQuery));

//  $(window).on('breakpoint-change', (e, breakpoint) => {
$(window).on('breakpoint-change', function(e, breakpoint) {
	// the breakpoints are aligned with zurb foundation
	if (breakpoint === 'small') { bpSmall(); }
	if (breakpoint === 'medium') { bpMedium(); }
	if (breakpoint === 'large') { bpLarge(); }
	if (breakpoint === 'xlarge') { bpXLarge(); }
	if (breakpoint === 'xxlarge') { bpXXLarge(); }
 });



// [[ fin ]]